import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Typography,
    Alert,
} from "@material-tailwind/react";
import { FaLeaf } from 'react-icons/fa';
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import axios from 'axios';

const DiseaseManagement = () => {
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [isPredicting, setIsPredicting] = useState(false);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);

        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            handleFileInput(droppedFile);
        }
    }, []);

    const handleFileInput = (selectedFile) => {
        if (selectedFile) {
            setFile(selectedFile);
            setImagePreview(URL.createObjectURL(selectedFile));
            setIsPredicting(true);
            // Clear any existing alert when new file is selected
            setAlert({ show: false, message: '', color: 'green' });
        }
    };

    const handleFileInputChange = useCallback((e) => {
        const selectedFile = e.target.files[0];
        handleFileInput(selectedFile);
    }, []);

    const handleUploadClick = () => {
        document.getElementById('file-upload').click();
    };

    const handlePredictClick = () => {
        if (file) {
            handleFile(file);
        } else {
            setAlert({
                show: true,
                message: 'Please select a file to upload.',
                color: 'red'
            });
        }
    };

    const handleFile = async (file) => {
        setLoading(true);
        // Show "Predicting..." message in the alert
        setAlert({
            show: true,
            message: 'Predicting...',
            color: 'blue'
        });

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/dm/v1/disease-management`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total > 0) {
                        setUploadProgress(Math.round((loaded * 100) / total));
                    }
                },
            });

            setAlert({
                show: true,
                message: `Prediction: ${response.data[0]}`,
                color: 'green'
            });
        } catch (error) {
            setAlert({
                show: true,
                message: 'Failed to upload file. Please try again.',
                color: 'red'
            });
        } finally {
            setLoading(false);
            setUploadProgress(0);
        }
    };

    const handleCleanup = () => {
        setFile(null);
        setImagePreview(null);
        setIsPredicting(false);
        setAlert({ show: false, message: '', color: 'green' });
        setUploadProgress(0);
        setLoading(false);
    };

    return (
        // <motion.div
        //     initial={{ opacity: 0, y: 20 }}
        //     animate={{ opacity: 1, y: 0 }}
        //     transition={{ duration: 0.5 }}
        //     className="flex min-h-screen p-8 bg-gradient-to-br from-green-400 to-blue-500 items-center justify-center"
        // >
        <div className='flex justify-center items-center'>
        <Card className="w-full max-w-[40rem] bg-white bg-opacity-90 backdrop-blur-md">
            <CardHeader
                color="blue"
                floated={false}
                shadow={false}
                className=" m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
            >
                <div className=" mb-4 rounded-full border border-white/10 bg-white/10 p-6 text-white">
                    <FaLeaf className="h-10 w-10" />
                </div>
                <Typography variant="h4" color="white">
                    Disease Prediction
                </Typography>
            </CardHeader>
            <CardBody>
                <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={`border-2 border-dashed rounded-lg p-8 text-center transition-all duration-200 ease-in-out cursor-pointer ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
                >
                    <CloudArrowUpIcon className="h-12 w-12 mx-auto text-gray-400" />
                    <Typography variant="h6" color="blue-gray" className="mt-4">
                        Drop file to upload
                    </Typography>
                    <Typography variant="small" color="gray" className="mt-2">
                        or
                    </Typography>
                    <div className="mt-4">
                        <label htmlFor="file-upload">
                            <Button
                                variant="gradient"
                                color="blue"
                                className="flex items-center gap-2 mx-auto"
                                onClick={isPredicting ? handlePredictClick : handleUploadClick}
                            >
                                <CloudArrowUpIcon className="h-5 w-5" />
                                {isPredicting ? 'Predict' : 'Upload File'}
                            </Button>
                            <input
                                id="file-upload"
                                type="file"
                                className="hidden"
                                onChange={handleFileInputChange}
                            />
                        </label>
                    </div>
                    {file && (
                        <Typography variant="small" color="gray" className="mt-4">
                            Selected: {file.name}
                        </Typography>
                    )}
                    {imagePreview && (
                        <img src={imagePreview} alt="Selected" className="mt-4 w-full h-auto" />
                    )}
                </div>

                {uploadProgress > 0 && uploadProgress < 100 && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                        <div
                            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                )}
            </CardBody>
            {alert.show && (
                <CardFooter className="pt-0">
                    <Alert
                        color={alert.color}
                        dismissible={{
                            onClose: () => setAlert({ ...alert, show: false }),
                        }}
                    >
                        {alert.message}
                    </Alert>
                    {isPredicting && (
                        <Button
                            variant="gradient"
                            color="red"
                            className="mt-4"
                            onClick={handleCleanup}
                        >
                            Clean Up
                        </Button>
                    )}
                </CardFooter>

            )}

        </Card>
        </div>
        // </motion.div>
    );
};

export default DiseaseManagement;