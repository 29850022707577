import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import Login from './Login/Login';
import Layout from './Layout/Layout';
import Dashboard from './Dashboard/Dashboard';
import UserList from './AdminConsole/User/UserList';
import UserForm from './AdminConsole/User/UserForm';
import TenantList from './AdminConsole/Tenant/TenantList';
import TenantForm from './AdminConsole/Tenant/TenantForm';
import OnboardTenant from './AdminConsole/OnboardTenant/OnboardTenant';
import FormData from './AdminConsole/OnboardTenant/FormData';
import FormBuilder from './AdminConsole/OnboardTenant/FormBuilder';
import KiaanGPT from './AdminConsole/KiaanGPT/KiaanGPT';
import ProcessList from './AdminConsole/Process/ProcessList';
import ProcessForm from './AdminConsole/Process/ProcessForm';
import PlantationProcessHistory from './SecondaryScreens/PlantationProcessHistory';
import LogProcess from './Tenant/LogProcess/LogProcess';
import IrrigationSchedule from './Irrigation/IrrigationSchedule/IrrigationSchedule';
import IrrigationHistoricalData from './Irrigation/IrrigationHistoricalData/IrrigationHistoricalData';
import SoilHistory from './SecondaryScreens/SoilHistory';
import WeatherHistory from './SecondaryScreens/WeatherHistory';
import EnvironmentHistory from './SecondaryScreens/EnvironmentHistory';
import Analytics from './AdminConsole/Analytics/Analytics';
import DiseaseManagement from './Tenant/DiseaseManagement/DiseaseManagement';

const App = () => {
  const { isLoggedIn, isAdmin } = useSelector((state) => state.login);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />

        <Route path="/admin" element={isLoggedIn && isAdmin ? <Layout /> : <LandingPage />}>
          <Route index element={<Dashboard />} />
          <Route path="analytics" element={<Analytics />} />

          <Route path="users" element={<UserList />} />
          <Route path="users/add" element={<UserForm />} />
          <Route path="users/edit/:userId" element={<UserForm />} />

          <Route path="kiaangpt" element={<KiaanGPT />} />
          <Route path="disease-management/prediction" element={<DiseaseManagement />} />

          <Route path="onboardtenant" element={<OnboardTenant />} />
          <Route path="onboardtenant/formbuilder" element={<FormBuilder />} />
          <Route path="onboardtenant/formdata" element={<FormData />} />

          <Route path="tenants" element={<TenantList />} />
          <Route path="tenants/add" element={<TenantForm />} />
          <Route path="tenants/edit/:tenantsId" element={<TenantForm />} />

          <Route path="process" element={<ProcessList />} />
          <Route path="process/add" element={<ProcessForm />} />
          <Route path="process/edit/:processId" element={<ProcessForm />} />
          <Route path="processhistory" element={<PlantationProcessHistory />} />

          <Route path="irrigation/schedule" element={<IrrigationSchedule />} />
          <Route path="irrigation/history" element={<IrrigationHistoricalData />} />
          <Route path="soilhistory" element={<SoilHistory />} />
          <Route path="weatherhistory" element={<WeatherHistory />} />
          <Route path="environmenthistory" element={<EnvironmentHistory />} />
        </Route>

        <Route path="/tenant" element={isLoggedIn ? <Layout /> : <LandingPage />}>
          <Route index element={<Dashboard />} />
          <Route path="processhistory" element={<PlantationProcessHistory />} />
          <Route path="logprocess" element={<LogProcess />} />
          <Route path="irrigation/schedule" element={<IrrigationSchedule />} />
          <Route path="irrigation/history" element={<IrrigationHistoricalData />} />
          <Route path="disease-management/prediction" element={<DiseaseManagement />} />
          <Route path="soilhistory" element={<SoilHistory />} />
          <Route path="weatherhistory" element={<WeatherHistory />} />
          <Route path="environmenthistory" element={<EnvironmentHistory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;